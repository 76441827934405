/*
MEIW - Programação Web Avançada - projeto final
Auhtor: Duarte Cota
Description: implementation of the header
*/

<template>
  <section class="container-fluid p-3">
    <router-link to="/"><img src="../assets/logo.jpg" alt="" /></router-link>
  </section>
</template>

<style scoped>
.nav-link {
  color: white !important;
}
.navbar {
  background-color: white;
  height: 120px;
}
img {
  max-width: 300px;
  height: auto;
}
</style>    