/*
MEIW - Programação Web Avançada - projeto final
Auhtor: Duarte Cota
Description: implementation of the view Home
*/

<template>
  <section class="container-fluid my-body">
    <img class="my-img" src="../assets/img.png" alt="image" />
    <h3>robótica && programação para todos</h3>
  </section>
</template>

<style scoped>
.my-body {
  background-color: white !important;
  text-align: center;
  padding-block: 100px;
}

.my-img {
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

h3 {
  color: #c55a11 !important;
  position: relative;
  margin-top: 40px;
  font-family: "Times New Roman", Times, serif;
  letter-spacing: 10px;
}
</style>     